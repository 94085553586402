import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

import Breached from "../images/breached.jpg"
import Creek from "../images/creek.jpg"
import TurnAround5 from "../images/turn_around_5.png"
import TurnAround2 from "../images/turn_around_2.jpg"
import TurnAround4 from "../images/turn_around_4.jpg"
import Forever from "../images/forever.jpg"
import Ateam from "../images/a_team.jpg"
import Blood from "../images/toiling_blood_2.jpg"
import Heron from "../images/heron.jpg"
import LowMoon from "../images/low_moon.jpg"
import Beginnings from "../images/beginnings_1.jpg"
import Canucks from "../images/canucks.jpg"
import Portslade from "../images/portslade.jpg"
import Wilderness from "../images/wilderness.jpg"
import Capilano from "../images/capilano.jpg"
import Blarney from "../images/blarney.jpg"
import Camera from "../images/camera.jpg"
import Reeds from "../images/reeds.jpg"
import Kissing from "../images/kissing.jpg"

const Lyric = styled.h3`
  margin-bottom: 18px;
`

const Box = styled.div`
  margin: 50px 0;
`

const Img = styled.img`
  margin: 0 auto;
  display: block;
`

const Spacer = styled.div`
  height: 60px;
  border: 2px dashed #1f9ab6;
  margin: 35px auto 45px;
  width: 1px;
`

const SpacerLong = styled.div`
  height: 100px;
  border: 2px dashed #1f9ab6;
  margin: 35px auto 45px;
  width: 1px;
`

class UsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
    }
  }

  componentDidMount = () => {
    if (window && window.localStorage.getItem("verified") !== "true") {
      window.location.href = "/"
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Us" />
        <Box>
          <Lyric>Down along the creek</Lyric>
          <Img src={Creek} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>I remember something</Lyric>
          <Img src={Beginnings} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>
            Her, the heron hurried away When first I breeched that last Sunday
            (Ooh)
          </Lyric>
          <Img src={Breached} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>Low moon don the yellow road</Lyric>
          <Img src={LowMoon} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>I remember something</Lyric>
          <Img src={Canucks} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>That leaving wasn't easing all that heaving in my vines</Lyric>
          <Img src={Portslade} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>
            And as certain it is evening 'at is NOW is not the Time (Ooooh)
          </Lyric>
          <Image name="evening" />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>Toiling with your blood</Lyric>
          <Img src={Blood} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>I remember something</Lyric>
          <Img src={Wilderness} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>In B, un—rationed kissing on a night second to last</Lyric>
          <Img src={Kissing} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>
            Finding both your hands as second sun came past the glass
          </Lyric>
          <Img src={Camera} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>And oh, I know it felt right and I had you in my grasp</Lyric>
          <Img src={Capilano} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>
            Oh then, how we gonna cry? Cause it once might not mean something?
          </Lyric>
          <Image name="cry" />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>
            Love, a second glance, it is not something that we'll need
          </Lyric>
          <Img src={Blarney} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>
            Honey, understand that I have been left here in the reeds
          </Lyric>
          <Img src={Reeds} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>And I see you</Lyric>
          <Img src={TurnAround2} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>Turn around, you're my A Team</Lyric>
          <Img src={TurnAround4} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>Turn around, now you're my A Team</Lyric>
          <Img src={TurnAround5} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>God damn, turn around now</Lyric>
          <Img src={Heron} />
          <div>
            <Spacer></Spacer>
          </div>
          <Lyric>You're my A Team</Lyric>
          <Img src={Ateam} />
          <div>
            <SpacerLong></SpacerLong>
          </div>
          <Img src={Forever} />
        </Box>
      </Layout>
    )
  }
}

export default UsPage
